import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { drawerWidth } from './Sidebar';
import { logout } from '../../services/authentication';

const headerHeight = 64;

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: headerHeight,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Advertiser Signup
        </Typography>
        <Button
          color="inherit"
          onClick={() => {
            logout();
            navigate('/');
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

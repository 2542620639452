import React from 'react';
import { navigate } from 'gatsby';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  sidebar: theme.mixins.toolbar,
}));

const paths = [
  {
    key: 'admin_users',
    path: '/admin_users/',
    text: 'Admin Users',
  },
  {
    key: 'signups',
    path: '/signups/',
    text: 'Sugnups',
  },
];

export default function Sidebar(props) {
  const { page } = props;
  const classes = useStyles();

  const currentPage = page;
  const isCurrentPage = (page) => page === currentPage;

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
    >
      <div className={classes.sidebar} />
      <Divider />
      <List>
        {paths.map((item) => (
          <ListItem
            button
            key={item.key}
            selected={isCurrentPage(item.key)}
            onClick={(e) => {
              navigate(item.path);
            }}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

import Cookies from 'js-cookie';

const host = process.env.BACKEND_HOST;
const authCookieName = 'auth';

export const login = ({ email, password }) => {
  return window
    .fetch(`${host}/admin/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      const token = json.data.token;
      if (token) {
        Cookies.set(authCookieName, token, { expires: 365 });
      }
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

export const logout = () => {
  Cookies.remove(authCookieName);
};

export const checkLogin = () => {
  const token = Cookies.get(authCookieName);
  return !!token;
};

export const authToken = () => {
  return Cookies.get(authCookieName);
};
